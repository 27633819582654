import React from "react";
import Flex from "../Flex";
import "./Appbar.scss";
import Logo from "../Logo";
import Profile from "../Profile";
import LocaleSwicher from "../LocaleSwitcher";
import Shortcut from './Shortcut'
import MitraDJP from "../MitraDJP";
import { inject, observer } from 'mobx-react'
import { themes } from "../../config/constant";

const Appbar = ({
  className     = '',
  brand         = null,
  subname       = null,
  inverseTheme  = true,
  style         = {},
  handleLogout  = null,
  handleProfile = null,
  username      = null,
  rightCorner   = null,
  otherPartner  = null,
  envStore,
  customAppbar  = null,
  menuItems     = [],
  ...props
}) => {
  const { appInfo } = envStore.env
  return (
  <Flex
      className={`mpk-appbar mpk-full full-width ${className} ${inverseTheme ? 'inverse' : ''}`}
      style={envStore.env.theme === themes.SOBATPAJAK ? {
        ...style,
        ...{
          background: '#689f38'
        }
      } : style}
      align={Flex.properties.align.CENTER}
      justify={Flex.properties.justify.BETWEEN}
      {...props}
    >
      <Flex align={Flex.properties.align.CENTER}>
        { brand ? brand : (
          appInfo.appname ? (
            <Logo.Product inverse={inverseTheme} appname={appInfo.appname}/>
          ) : (
            <Logo light={inverseTheme}/>
          )
        )}
        { subname || appInfo.subname ? (
          <span 
            className="mpk-margin-N left mpk-font size-NS" 
            style={{opacity:.64}}
          >
            {subname || appInfo.subname}
          </span>
        ) : null}
         {customAppbar}
      </Flex>
      <Flex align={Flex.properties.align.CENTER}>
      <Profile handleApplicationInfo={props.handleApplicationInfo} handleLogout={handleLogout} menuItems={menuItems} handleProfile={handleProfile} username={username} />
        {rightCorner}
				<LocaleSwicher/>
        <Shortcut/>
        <MitraDJP
          className="mpk-margin-S margin-left"
          inverse={inverseTheme}
        />
        {otherPartner}
			</Flex>
    </Flex>
  );
};

export default inject('envStore')(observer(Appbar));
